<template>
  <div class="con-wrap">
    <CarrotTitle title="커뮤니케이션 서약 관리">
      - 커뮤니케이션 서약서 제출여부를 확인할 수 있는 페이지입니다.<br />
      - 서약파일을 클리하면 다운로드할 수 있습니다.
    </CarrotTitle>

    <div class="board mt-40" v-if="comp.isLoaded == true">
      <div class="mb-20">
        <!-- 현재연도 -->
        <select
          class="w-200px"
          v-model="comp.syear"
          @change="comp.searchAgreement()"
        >
          <option
            v-for="(year, idx) in comp.search_years"
            :key="idx"
            :value="year"
          >
            {{ year }}
          </option>
        </select>

        <!-- 제출여부 -->
        <select
          class="w-200px ml-10"
          v-model="comp.is_submit"
          @change="comp.searchAgreement()"
        >
          <option value="">제출 여부</option>
          <option value="Y">제출</option>
          <option value="N">미제출</option>
        </select>

        <!-- 부문 -->
        <CarrotSector
          class="w-200px ml-10"
          v-if="comp.isLoaded == true"
          v-model="comp.idx_sector"
          @change="comp.searchSector()"
        ></CarrotSector>

        <!-- 본부 -->
        <CarrotDept
          class="w-200px ml-10"
          v-if="comp.isLoaded == true"
          :idx_sector="comp.idx_sector"
          v-model="comp.idx_office"
          @change="comp.searchTeam()"
        ></CarrotDept>

        <!-- 팀 -->
        <CarrotTeam
          class="w-200px ml-10"
          v-if="comp.isLoaded == true"
          :idx_sector="comp.idx_sector"
          :idx_office="comp.idx_office"
          v-model="comp.idx_team"
          @change="comp.changeTeam()"
        ></CarrotTeam>
      </div>

      <table class="table-col">
        <thead>
          <tr>
            <th width="60">No</th>
            <th width="150">부문</th>
            <th width="150">본부</th>
            <th width="150">팀</th>
            <th width="150">팀원</th>
            <th width="150">직급</th>
            <th>서약파일</th>
          </tr>
        </thead>
        <tbody>
          <!-- 정보 있을 때 -->
          <tr v-for="(irow, aIdx) in comp.commAgreementList" :key="aIdx">
            <td>
              <p>{{ irow.num }}</p>
            </td>
            <td>
              <p>{{ irow.sector_name }}</p>
            </td>
            <td>
              <p>{{ irow.office_name }}</p>
            </td>
            <td>
              <p>{{ irow.team_name }}</p>
            </td>
            <td>
              <p>{{ irow.ename }}({{ irow.kname }})</p>
            </td>
            <td>
              <p>{{ irow.position_name }}</p>
            </td>
            <td>
              <p v-if="irow.file1_name_list == '-'">미제출</p>
              <ul v-else class="file-list">
                <li
                  v-for="(jrow, bIdx) in irow.file1_name_list
                    .split('|')
                    .reverse()"
                  :key="bIdx"
                >
                  <p>•</p>
                  <a
                    :href="comp.file1_url_list[aIdx][bIdx]"
                    :download="comp.file1_name_list[aIdx][bIdx]"
                    target="_blank"
                    class="line-clamp-1"
                  >
                    {{ jrow }}
                  </a>
                </li>
              </ul>
            </td>
          </tr>

          <!-- 정보 없을 때 -->
          <tr v-if="comp.total == 0">
            <td colspan="7">정보가 없습니다.</td>
          </tr>
        </tbody>
      </table>

      <CarrotPaging
        v-if="comp.total > 0"
        :total="comp.total"
        :list_per_page="comp.rows"
        v-model="comp.page"
        @change="comp.searchAgreement()"
      ></CarrotPaging>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import axios from "@/plugins/axios.js";
import CarrotSector from "@/components/common/CarrotSector.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";

export default {
  layout: "myPIMS",
  components: { CarrotSector, CarrotDept, CarrotTeam },
  setup() {
    const router = new useRouter();
    const store = useStore();

    const comp = reactive({
      isLoaded: false, //페이지 로딩 여부
      syear: "", //현재연도
      search_years: [], //현재연도 담은 값
      page: 1, //페이지
      rows: 50, //한 페이지에 보여지는 개수
      total: 0, //전체 인원
      num: 0, //테이블 각 인원 고유 번호
      sector_name: "", //테이블 부문
      office_name: "", //테이블 본부
      team_name: "", //테이블 팀
      ename: "", //테이블 영어 이름
      kname: "", //테이블 한글 이름
      position_name: "", //테이블 직급
      idx_sector: 0, //부문
      idx_office: 0, //본부
      idx_team: 0, //팀
      is_submit: "", //제출 여부
      sectorList: [], //부문 목록
      officeList: [], //본부 목록
      teamList: [], //팀 목록
      commAgreementList: [], //서약서 목록
      file1_url_list: [], //첨부파일 주소
      file1_name_list: [], //첨부파일 이름

      // 날짜 조회
      searchYears: () => {
        let nd = new Date();
        let ng = nd.getFullYear();
        for (let i = ng; i > 2023; i--) {
          comp.search_years.push(i);
        }
      },

      // 부문 리스트 조회
      searchSector: () => {
        comp.idx_office = 0;
        comp.idx_team = 0;
        comp.searchAgreement();

        axios.get("/rest/component/getSectorList").then((res) => {
          if (res.data.err == 0) {
            comp.sectorList = res.data.list;
          } else {
            console.info(res.data.err_msg);
          }
        });
      },

      // 팀 리스트 조회
      searchTeam: () => {
        comp.idx_team = 0;
        comp.searchAgreement();

        let params = {
          idx_sector: comp.idx_sector,
          idx_office: comp.idx_office,
        };
        axios
          .get("/rest/component/getTeamList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              comp.teamList = res.data.list;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
      changeTeam: () => {
        comp.searchAgreement();

        let params = {
          idx_sector: comp.idx_sector,
          idx_office: comp.idx_office,
        };
        axios
          .get("/rest/component/getTeamList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              comp.teamList = res.data.list;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },

      // 목록 검색
      searchAgreement: () => {
        if (comp.isLoaded == false) return;

        let params = {
          page: comp.page,
          rows: comp.rows,
          syear: comp.syear,
          idx_sector: comp.idx_sector,
          idx_office: comp.idx_office,
          idx_team: comp.idx_team,
          is_submit: comp.is_submit,
        };

        axios
          .get("/rest/mypims/getCommAgreementList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              comp.commAgreementList = res.data.list;
              comp.total = res.data.total;
              comp.file1_url_list = res.data.list.map((item) => {
                return item.file1_url_list.split("|").reverse();
              });

              comp.file1_name_list = res.data.list.map((item) => {
                return item.file1_name_list.split("|").reverse();
              });
            } else {
              console.info(res.data.err_msg);
            }
          });
      },

      // 내 정보 조회
      getMyInfo: () => {
        axios.get("/rest/mypims/getMyInfo", { params: {} }).then((res) => {
          if (res.data.err == 0) {
            comp.info = res.data;
            comp.idx_sector = res.data.idx_sector;
            comp.idx_office = res.data.idx_office;
            comp.idx_team = res.data.idx_team;
            comp.idx_staff = res.data.idx;
            comp.isAuth = res.data.is_auth;

            // 권한
            if (comp.isAuth == "N" && store.state.pd_auth == false) {
              comp.isLoaded = false;
              Swal.fire({
                title: "커뮤니케이션 서약 관리",
                text: "접근 권한이 없습니다.",
              }).then(() => {
                router.go(-1);
              });
              return;
            }

            comp.isLoaded = true;
            comp.searchAgreement();
          }
        });
      },
    });

    onMounted(() => {
      let nd = new Date();
      comp.syear = nd.getFullYear();

      comp.searchYears();
      comp.getMyInfo();

      // 권한
      if (store.state.position != "리드" && store.state.pd_auth == false) {
        router.go(-1);

        Swal.fire({
          title: "커뮤니케이션 서약 관리",
          text: "접근 권한이 없습니다.",
        });
        return;
      }
    });

    return { comp, store };
  },
};
</script>
<style lang="scss" scoped>
.file-list {
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    gap: 4px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;

    a {
      text-decoration: underline;
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border: 0;
    }
  }
}
</style>
